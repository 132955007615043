import { Component, OnInit } from '@angular/core';

import { IndicadorService } from '../../api/indicador.service'
import { MessageService } from '../../services/message.service'

import { Indicador } from '../../models/indicador'
import { IndicadorDetalle } from '../../models/indicador-detalle'
import { Calificacion } from '../../models/calificacion'
import { Chart } from 'angular-highcharts';
import { IndicadoresService } from '../../services/indicadores.service'

enum GraphType {
  CLIENTES = "Cliente" ,
  INGRESOS = "Ingreso",
  QUEJAS = "Queja"
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private indicador: Indicador
  private indicadorDetalle: IndicadorDetalle[]
  private calificaciones: Calificacion[]
  solicitudesPieChart: Chart
  ingeresosColumnChart: Chart
  clientesLinechart: Chart
  quejasLinechart: Chart
  idPeriodo:number = -1
  idEmpresa:number = -1
  idEmpleado:number = -1
  mes:number = -1
  semana:number = -1
  showCalificacion: boolean = false

  constructor(
    private indicadorService: IndicadorService,
    private indicadoresService: IndicadoresService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.indicadoresService.currentMes.subscribe(mes => {
      if(mes != -1) {
        this.mes = mes
        this.showIndicadores()
      }
    });
    this.indicadoresService.currentSemana.subscribe(semana => {
      this.semana = semana
      this.showIndicadores()
    });
    this.indicadoresService.currentIdEmpleado.subscribe(idEmpleado => {
      if(idEmpleado != -1) {
        this.idEmpleado = idEmpleado
        this.showIndicadores()
        this.showCalificacion = true
      } else {
        this.showCalificacion = false
      }
    });
    this.indicadoresService.currentIdEmpresa.subscribe(idEmpresa => {
      this.idEmpresa = idEmpresa
    });
    this.indicadoresService.currentPeriodo.subscribe(idPeriodo => {
      this.idPeriodo = idPeriodo
      this.showIndicadores()
    });
  }

  showIndicadores() {
    switch(+this.idPeriodo) {
      case 1:
        this.clearGraphs()
        if(this.semana != -1) {
          if(this.idEmpleado != -1) {
            this.showProfesionalSemanal(this.idEmpleado, this.semana)
          } else {
            this.showEmpresaSemanal(this.idEmpresa, this.semana)
          }
        }
      break;
      case 3:
        this.clearGraphs()
        if(this.mes != -1) {
          if(this.idEmpleado != -1) {
            this.showProfesionalMensual(this.idEmpleado, this.mes)
          } else {
            this.showEmpresaMensual(this.idEmpresa, this.mes)
          }
        }
      break;
      default:
        this.clearGraphs()
      break;
    }
  }

  clearGraphs() {
    this.initSolicitudesPieChartNoData() 
    this.getIndicadorDetalle([], GraphType.CLIENTES)
    this.getIndicadorDetalle([], GraphType.INGRESOS)
    this.getIndicadorDetalle([], GraphType.QUEJAS)
    this.calificaciones = []
  }

  showEmpresaSemanal(idEmpresa, semana) {
    this.getEmpresaIndicadorSemanalById(idEmpresa, semana)
    this.getEmpresaIngresosSemanalById(idEmpresa, semana)
    this.getEmpresaClientesSemanalById(idEmpresa, semana)
    this.getEmpresaQuejasSemanalById(idEmpresa, semana)
  }

  showEmpresaMensual(idEmpresa, mes) {
    this.getEmpresaIndicadorMensualById(idEmpresa, mes)
    this.getEmpresaIngresosMensualById(idEmpresa, mes)
    this.getEmpresaClientesMensualById(idEmpresa, mes)
    this.getEmpresaQuejasMensualById(idEmpresa, mes)
  }

  showProfesionalSemanal(idProfesional, semana) {
    this.getEspecialistaIndicadorSemanalById(idProfesional, semana)
    this.getEspecialistaIngresosSemanalById(idProfesional, semana)
    this.getEspecialistaClientesSemanalById(idProfesional, semana)
    this.getEspecialistaQuejasSemanalById(idProfesional, semana)
    this.getEspecialistaCalificacionSemanalById(idProfesional, semana)
  }

  showProfesionalMensual(idProfesional, mes) {
    this.getEspecialistaIndicadorMensualById(idProfesional, mes)
    this.getEspecialistaIngresosMensualById(idProfesional, mes)
    this.getEspecialistaClientesMensualById(idProfesional, mes)
    this.getEspecialistaQuejasMensualById(idProfesional, mes)
    this.getEspecialistaCalificacionMensualById(idProfesional, mes)
  }

  initSolicitudesPieChart(datos:any[]) {
    let pieChart = new Chart({
      chart: {
        type: 'pie'
      },
      title: {
        text: 'Solicitudes'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.percentage:.1f} %',
          }
        }
      },
      series : [{
        name: 'Solicitudes',
        data: [{
            name: 'Pendientes',
            y: parseInt(datos[0]),
            color: '#C6B2E7',
          }, {
            name: 'Finalizadas',
            y: parseInt(datos[1]),
            color: '#4200AF',
          }, {
            name: 'Canceladas',
            y: parseInt(datos[2]),
            color: '#D30083',
        }]
      }],
    });
    this.solicitudesPieChart = pieChart;
  }

  initIngeresosColumnChart(datos:any[]) {
    //console.log(datos)
    let columnChart = new Chart({
      chart: {
        type: 'column'
      },
      title: {
        text: 'Ingresos por día'
      },
      legend: {
        enabled: false
      },
      xAxis: {
        tickInterval: 1,
        min: 1,
        title: {
          text: 'Días'
        }
      },
      yAxis: {
        title: {
            text: 'MXN'
        },
        min: 0
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: "${point.y:,.2f}"
      },
      series : [{
        data: datos
      }],
      colors: ['#2E007A'],
      lang: {
        noData: "No hay datos para mostrar"
      },
    });
    this.ingeresosColumnChart = columnChart;
  }

  initClientesLinechart(datos:any[]) {
    let chart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: 'Clientes atentidos por día'
      },
      legend: {
        enabled: false
      },
      xAxis: {
        tickInterval: 1,
        min: 0
      },
      yAxis: {
        title: {
            text: ''
        },
        tickInterval: 2,
        min: 0
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Clientes',
        data: datos
      }],
      colors: ['#2E007A'],
      lang: {
        noData: "No hay datos para mostrar"
      },
    });
    this.clientesLinechart = chart;
  }

  initQuejasLinechart(datos:any[]) {
    let chart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: 'Quejas por día'
      },
      legend: {
        enabled: false
      },
      xAxis: {
        tickInterval: 1,
        min: 1
      },
      yAxis: {
        title: {
            text: ''
        },
        tickInterval: 2,
        min: 1
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Quejas',
        data: datos
      }],
      colors: ['#D30083'],
      lang: {
        noData: "No hay datos para mostrar"
      },
    });
    this.quejasLinechart = chart;
  }

  initSolicitudesPieChartNoData() {
    let pieChart = new Chart({
      title: {
        text: 'Solicitudes'
      },
      credits: {
        enabled: false
      },
      series : [{
        type: 'pie',
        name: 'Servicios',
        data: []
      }],
      lang: {
        noData: "No hay datos para mostrar"
      },
    });
    this.solicitudesPieChart = pieChart;
  }

  getIndicadorData(indicador) {
    let that = this
    that.indicador = indicador
    const data:number[] = [that.indicador.servicios - that.indicador.finalizados - that.indicador.cancelados, that.indicador.finalizados, that.indicador.cancelados]
    if(data[0] > 0 && data[1] > 0 && data[2] > 0) {
      that.initSolicitudesPieChart(data)
    } else {
      that.initSolicitudesPieChartNoData();
    }
  }

  getIndicadorDetalle(indicadorDetalle, tipo) {
    let that = this
    that.indicadorDetalle = indicadorDetalle
    var data:Array<number> = [];
    if (indicadorDetalle != undefined || indicadorDetalle.length != 0) {
      data.push(0)
      for(let detalle of that.indicadorDetalle) {
        data.push(parseFloat(detalle.valor.toString()))
      }
    }
    switch(tipo) {
      case GraphType.INGRESOS: {
        that.initIngeresosColumnChart(data)
         break;
      }
      case GraphType.CLIENTES: {
        that.initClientesLinechart(data)
         break;
      }
      case GraphType.QUEJAS: {
        that.initQuejasLinechart(data)
         break;
      }
      default: {
         break;
      }
    }
  }

  columnDefs = [
    {headerName: 'Fecha Solicitud', field: 'fechaSolicitud'},
    {headerName: 'Fecha Calificacion', field: 'fechaCreacion'},
    {headerName: 'Cliente', field: 'cliente' },
    {headerName: 'Calificacion', field: 'calificacion'},
    {headerName: 'Comentario', field: 'comentario'},
  ];

  private getEmpresaIndicadorSemanalById(idEmpresa, semana) {
    let that = this
    that.indicadorService.getEmpresaIndicadorSemanalById(idEmpresa, semana, function(indicador, error) {
      if (indicador != null) {
        that.getIndicadorData(indicador[0])
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaIndicadorMensualById(idEmpresa, mes) {
    let that = this
    that.indicadorService.getEmpresaIndicadorMensualById(idEmpresa, mes, function(indicador, error) {
      if (indicador != null) {
        that.getIndicadorData(indicador[0])
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaClientesSemanalById(idEmpresa, semana) {
    let that = this
    that.indicadorService.getEmpresaClientesSemanalById(idEmpresa, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.CLIENTES)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaClientesMensualById(idEmpresa, mes) {
    let that = this
    that.indicadorService.getEmpresaClientesMensualById(idEmpresa, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.CLIENTES)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaIngresosSemanalById(idEmpresa, semana) {
    let that = this
    that.indicadorService.getEmpresaIngresosSemanalById(idEmpresa, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.INGRESOS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaIngresosMensualById(idEmpresa, mes) {
    let that = this
    that.indicadorService.getEmpresaIngresosMensualById(idEmpresa, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.INGRESOS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaQuejasSemanalById(idEmpresa, semana) {
    let that = this
    that.indicadorService.getEmpresaQuejasSemanalById(idEmpresa, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.QUEJAS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEmpresaQuejasMensualById(idEmpresa, mes) {
    let that = this
    that.indicadorService.getEmpresaQuejasMensualById(idEmpresa, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.QUEJAS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaIndicadorSemanalById(idEspecialista, semana) {
    let that = this
    that.indicadorService.getEspecialistaIndicadorSemanalById(idEspecialista, semana, function(indicador, error) {
      if (indicador != null) {
        that.getIndicadorData(indicador[0])
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaIndicadorMensualById(idEspecialista, mes) {
    let that = this
    that.indicadorService.getEspecialistaIndicadorMensualById(idEspecialista, mes, function(indicador, error) {
      if (indicador != null) {
        that.getIndicadorData(indicador[0])
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaCalificacionSemanalById(idEspecialista, semana) {
    let that = this
    that.calificaciones = []
    that.indicadorService.getEspecialistaCalificacionSemanalById(idEspecialista, semana, function(calificacion, error) {
      if (calificacion != null) {
        that.calificaciones = calificacion
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaCalificacionMensualById(idEspecialista, mes) {
    let that = this
    that.indicadorService.getEspecialistaCalificacionMensualById(idEspecialista, mes, function(calificacion, error) {
      if (calificacion != null) {
        that.calificaciones = calificacion
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaClientesSemanalById(idEspecialista, semana) {
    let that = this
    that.indicadorService.getEspecialistaClientesSemanalById(idEspecialista, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.CLIENTES)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaClientesMensualById(idEspecialista, mes) {
    let that = this
    that.indicadorService.getEspecialistaClientesMensualById(idEspecialista, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.CLIENTES)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaIngresosSemanalById(idEspecialista, semana) {
    let that = this
    that.indicadorService.getEspecialistaIngresosSemanalById(idEspecialista, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.INGRESOS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaIngresosMensualById(idEspecialista, mes) {
    let that = this
    that.indicadorService.getEspecialistaIngresosMensualById(idEspecialista, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.INGRESOS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaQuejasSemanalById(idEspecialista, semana) {
    let that = this
    that.indicadorService.getEspecialistaQuejasSemanalById(idEspecialista, semana, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.QUEJAS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getEspecialistaQuejasMensualById(idEspecialista, mes) {
    let that = this
    that.indicadorService.getEspecialistaQuejasMensualById(idEspecialista, mes, function(indicadorDetalle, error) {
      if (indicadorDetalle != null) {
        that.getIndicadorDetalle(indicadorDetalle, GraphType.QUEJAS)
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }
}
