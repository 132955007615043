import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"
import { NgForm } from '@angular/forms'

import { AngularFireAuth } from 'angularfire2/auth'
import { StorageService } from '../../services/storage.service'
import { MessageService } from '../../services/message.service'
import { LoginService } from '../../api/login.service'
import { CompanyService } from '../../api/company.service'

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private tokenKey = 'token'
  private companyKey = 'company'

  public email: string = ''
  public password: string = ''

  constructor(
  	private router: Router,
    private auth: AngularFireAuth,
    private storageService: StorageService,
    private messageService: MessageService,
    private loginService: LoginService,
    private companyService: CompanyService) { }

  ngOnInit() {
  	this.isUserLogguedIn()
  }

  login() {
    if (this.isValid()) {
      this.firebaseLogin()
    }
  }

  private isUserLogguedIn() {
    if (this.storageService.exists(this.tokenKey)) {
      console.log(this.tokenKey)
      this.router.navigate(['']) 
    }
  }

  private firebaseLogin() {
    let that = this
    this.toggleLoading()
    this.auth.auth.signInWithEmailAndPassword(this.email, this.password)
      .then(function(credentials) {
        credentials.user.getIdToken().then(function(token) {
          that.getUserToken(token)
        }).catch(function(error) {
          this.toggleLoading()
          that.handleFirebaseError(error)
        })
    }).catch(function(error) {
      that.toggleLoading()
      that.handleFirebaseError(error)
      console.log(error)
    })
  }

  private getUserToken(token) {
    let that = this
    this.loginService.verifyToken(token, function(auth, error) {
      if (auth != null) {
        that.getCompany(auth.token)
      } else if(error != null) {
        that.toggleLoading()
        that.messageService.show(error.error.message)
      } else {
        that.toggleLoading()
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getCompany(token) {
    let that = this
    that.companyService.getByEmail(this.email, function(company, error) {
      that.toggleLoading()
      if (company != null) {
        that.storageService.save(that.tokenKey, token)
        that.storageService.save(that.companyKey, JSON.stringify(company))
        location.reload()
      } else if(error != null) {
        that.toggleLoading()
        that.messageService.show(error.error.message)
      } else {
        that.toggleLoading()
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private isValid(): boolean {
    if(this.email.length == 0) {
      this.messageService.show("Ingrese su correo electrónico");
      return false
    }

    var expression = /^(([^<>()[\]\\.,:\s@\"]+(\.[^<>()[\]\\.,:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    
    if (!expression.test(this.email)) {
      this.messageService.show("El correo electrónico no es válido");
      return false
    }

    if (this.password.length == 0) {
      this.messageService.show("Ingrese su contraseña");
      return false
    }

    return true
  }

  private toggleLoading() {
    $("#login-button").toggle()
    $("#loader").toggle()
  }

  private handleFirebaseError(error) {
    console.log(error)
    switch (error.code) {
      case "auth/user-not-found":
        this.messageService.show("El correo electronico no esta registrado")
        break;
      case "auth/wrong-password":
        this.messageService.show("La contraseña es incorrecta")
        break;
      default:
        this.messageService.show("Ocurrio un error inesperado")
        break;
    }
  }
}
