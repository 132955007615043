import { Component, OnInit } from '@angular/core';

import { ServiceService } from '../../api/service.service'
import { MessageService } from '../../services/message.service'

import { Service } from '../../models/service'
import { IndicadoresService } from '../../services/indicadores.service'

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: Service[]
  idPeriodo:number = -1
  idEmpresa:number = -1
  idEmpleado:number = -1
  mes:number = -1
  semana:number = -1

  columnDefs = [
    {headerName: 'Profesional', field: 'nombreEspecialista', width: 150 },
    {headerName: 'No. Solicitud', field: 'idSolicitud', width: 150 },
    {headerName: 'Fecha', field: 'fecha', width: 120},
    {headerName: 'Hora', field: 'hora', width: 100},
    {headerName: 'Servicio', field: 'nombreServicio', width: 300},
    {headerName: 'Precio', field: 'precioUnitario', width: 150, valueFormatter: currencyFormatter, cellClass: 'number-cell'},
    {headerName: 'Cantidad', field: 'cantidad', width: 150, cellClass: 'number-cell'},
    {headerName: 'Importe', field: 'importe', width: 150, valueFormatter: currencyFormatter, cellClass: 'number-cell'},
    {headerName: 'Impuesto', field: 'impuesto', width: 150, valueFormatter: currencyFormatter, cellClass: 'number-cell'},
    {headerName: 'Total', field: 'total', width: 150, valueFormatter: currencyFormatter, cellClass: 'number-cell'}
  ];

  rowData = [{}];

  constructor(
    private serviceService: ServiceService,
    private messageService: MessageService,
    private indicadoresService: IndicadoresService) { }

  ngOnInit() {
    this.indicadoresService.currentMes.subscribe(mes => {
      this.mes = mes
      this.showIndicadores()
    });
    this.indicadoresService.currentSemana.subscribe(semana => {
      this.semana = semana
      this.showIndicadores()
    });
    this.indicadoresService.currentIdEmpleado.subscribe(idEmpleado => {
      this.idEmpleado = idEmpleado
      this.showIndicadores()
    });
    this.indicadoresService.currentIdEmpresa.subscribe(idEmpresa => {
      this.idEmpresa = idEmpresa
    });
    this.indicadoresService.currentPeriodo.subscribe(idPeriodo => {
      this.idPeriodo = idPeriodo
      this.showIndicadores()
    });
  }

  showIndicadores() {
    switch(+this.idPeriodo) {
      case 1:
        this.services = []
        if(this.semana != -1) {
          if(this.idEmpleado != -1) {
            this.getServiciosSemanalesProfesional(this.idEmpleado, this.semana)
          } else {
            this.getServiciosSemanalesEmpresa(this.idEmpresa, this.semana)
          }
        }
      break;
      case 3:
        this.services = []
        if(this.mes != -1) {
          if(this.idEmpleado != -1) {
            this.getServiciosMensualesProfesional(this.idEmpleado, this.mes)
          } else {
            this.getServiciosMensualesEmpresa(this.idEmpresa, this.mes)
          }
        }
      break;
      default:
        this.services = []
      break;
    }
  }

  private getServiciosSemanalesEmpresa(idEmpresa, semana) {
    let that = this
    that.serviceService.getServicioSemanalEmpresa(idEmpresa, semana, function(services, error) {
      if (services != null) {
        that.services = services
        that.rowData = that.services
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getServiciosMensualesEmpresa(idEmpresa, mes) {
    let that = this
    that.serviceService.getServicioMensualEmpresa(idEmpresa, mes, function(services, error) {
      if (services != null) {
        that.services = services
        that.rowData = that.services
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getServiciosSemanalesProfesional(idProfesional, semana) {
    let that = this
    that.serviceService.getServicioSemanalProfesional(idProfesional, semana, function(services, error) {
      if (services != null) {
        that.services = services
        that.rowData = that.services
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }

  private getServiciosMensualesProfesional(idProfesional, mes) {
    let that = this
    that.serviceService.getServicioMensualProfesional(idProfesional, mes, function(services, error) {
      if (services != null) {
        that.services = services
        that.rowData = that.services
      } else if(error != null) {
        that.messageService.show(error.error.message)
      } else {
        that.messageService.show("Ocurrio un error inesperado")
      }
    })
  }
}

function currencyFormatter(params) {
  return "$ " + formatNumber(params.value);
}

function formatNumber(number) {
  return Math.floor(number)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
