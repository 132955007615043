import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Service } from '../models/service'

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends ApiService {

  private getEmpresaServicioSemanalUrl = 'empresas/consulta/solicitudes/{idEmpresa}/semanal/{semana}'
  private getEmpresaServicioMensualUrl = 'empresas/consulta/solicitudes/{idEmpresa}/mensual/{mes}'
  private getlProfesionalServicioSemanaUrl = 'especialistas/consulta/solicitudes/{idEspecialista}/semanal/{semana}'
  private getlProfesionalServicioMensualUrl = 'especialistas/consulta/solicitudes/{idEspecialista}/mensual/{mes}'
  private headers = {headers: {'Cache-Control' : 'no-cache'}}

  constructor(private http: HttpClient) {
  	super()
  }

  getServicioSemanalEmpresa(idEmpresa, semana, callback) {
    var uri = this.baseUrl + this.getEmpresaServicioSemanalUrl.replace("{idEmpresa}", idEmpresa).replace("{semana}", semana)
    this.http.get<[Service]>(uri, this.headers).subscribe(services => {
      callback(services, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getServicioMensualEmpresa(idEmpresa, mes, callback) {
    var uri = this.baseUrl + this.getEmpresaServicioMensualUrl.replace("{idEmpresa}", idEmpresa).replace("{mes}", mes)
    this.http.get<[Service]>(uri, this.headers).subscribe(services => {
      callback(services, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getServicioSemanalProfesional(idProfesional, semana, callback) {
    var uri = this.baseUrl + this.getlProfesionalServicioSemanaUrl.replace("{idEspecialista}", idProfesional).replace("{semana}", semana)
    this.http.get<[Service]>(uri, this.headers).subscribe(services => {
      callback(services, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getServicioMensualProfesional(idProfesional, mes, callback) {
    var uri = this.baseUrl + this.getlProfesionalServicioMensualUrl.replace("{idEspecialista}", idProfesional).replace("{mes}", mes)
    this.http.get<[Service]>(uri, this.headers).subscribe(services => {
      callback(services, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }
}
