import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { TimePeriod } from '../models/time-period'

@Injectable({
  providedIn: 'root'
})
export class TimePeriodService extends ApiService {

	private getUrl = 'catalogos/periodos-tiempo'
	private headers = {headers: {'Cache-Control' : 'no-cache'}}
	
  constructor(private http: HttpClient) {
  	super()
  }

  get(callback) {
  		var uri = this.baseUrl + this.getUrl
		this.http.get<[TimePeriod]>(uri, this.headers).subscribe(timePeriods => {
			callback(timePeriods, null)
		}, error => {
	    	if (error instanceof ErrorEvent) {
	      		callback(null, null)
		    } else {
		      callback(null, error.error)
		    }
		})
  }
}
