import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Semana } from '../models/semana'

@Injectable({
  providedIn: 'root'
})
export class SemanaService extends ApiService {

  private getUrl = 'indicadores/consulta/semanas'
  private headers = {headers: {'Cache-Control' : 'no-cache'}}

  constructor(private http: HttpClient) {
  	super()
  }

  get(callback) {
    var uri = this.baseUrl + this.getUrl
    this.http.get<[Semana]>(uri, this.headers).subscribe(semanas => {
      callback(semanas, null)
    }, error => {
        if (error instanceof ErrorEvent) {
            callback(null, null)
        } else {
          callback(null, error.error)
        }
    })
  }
}
