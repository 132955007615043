import { Component, OnInit } from '@angular/core'
import { Router } from "@angular/router"

import { StorageService } from './services/storage.service'
import { MessageService } from './services/message.service'
import { IndicadoresService } from './services/indicadores.service'

import { ProfessionalService } from './api/professional.service'
import { TimePeriodService } from './api/time-period.service'
import { MesService } from './api/mes.service'
import { SemanaService } from './api/semana.service'

import { Company } from './models/company'
import { Professional } from './models/professional'
import { TimePeriod } from './models/time-period'
import { Mes } from './models/mes'
import { Semana } from './models/semana'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  
  private tokenKey = 'token'
  private companyKey = 'company'
  
  public company: Company
  public timePeriods: [TimePeriod]
  public meses: [Mes]
  public semanas: [Semana]
  public professionals: [Professional]
  public timePeriodSelected: TimePeriod
  public mesSelected: Mes
  public semanaSelected: Semana
  public professionalSelected: Professional

  public title = 'Rezolv Portal'
  public companyName = ''
  public periodTimeText = 'Periodo de tiempo'
  public professionalText = 'Mostrar Todos'
  public mesText = 'Seleccionar Mes'
  public semanaText = 'Seleccionar Semana'

  public show = true
  showFilter: boolean = false
  showFilterSemana: boolean = false
  showFilterMes: boolean = false
  showCalificacion: boolean = false
  rate = 0
  
  constructor(
    private router: Router,
    private messageService: MessageService,
    private storageService: StorageService,
    private professionalService: ProfessionalService,
    private timePeriodService: TimePeriodService,
    private mesService: MesService,
    private SemanaService: SemanaService,
    private indicadoresService: IndicadoresService) {}

  ngOnInit() {
    console.log("Principal")
  	this.show = this.storageService.exists(this.tokenKey)
    
    if (!this.show) {
      this.router.navigate(['/login']) 
      return
    }

    this.showCompany()
    this.getTimePeriods()
    this.getProfessionals()
    this.getMeses()
    this.getSemanas()
  }

  logout() {
    this.storageService.clear()
    location.reload()
  }

  setProfessional(professional: Professional) {
    this.professionalSelected = professional
    this.indicadoresService.setProfesional(professional.idEspecialista)
    this.rate = professional.calificacion
    if(professional.idEspecialista != -1) {
      this.showCalificacion = true
    } else {
      this.showCalificacion = false
    }
  }

  setTimePeriod(timePeriod: TimePeriod) {
    this.timePeriodSelected = timePeriod
    this.indicadoresService.setPeriodo(timePeriod.idPeriodo)
    if(this.professionalSelected != undefined) {
      if(this.professionalSelected.idEspecialista != -1) {
        this.showCalificacion = true
      } else {
        this.showCalificacion = false
      }
    }
    switch(+timePeriod.idPeriodo) {
      case 1:
        this.showFilterSemana = true
        this.showFilterMes = false
        this.showFilter = true
        break;
      case 3:
        this.showFilterSemana = false
        this.showFilterMes = true
        this.showFilter = true
        break;
      default:
        this.showFilterSemana = false
        this.showFilterMes = false
        this.showFilter = false
        this.showCalificacion = false
        break;
    }
  }

  setMes(mes: Mes) {
    this.mesSelected = mes
    this.indicadoresService.setMes(mes.mes)
  }
  
  setSemana(semana: Semana) {
    this.semanaSelected = semana
    this.indicadoresService.setSemana(semana.orden)
  }

  private showCompany() {
    this.company = JSON.parse(this.storageService.get(this.companyKey))
    this.indicadoresService.setEmpresa(this.company.idEmpresa)
  }

  private getProfessionals() {
    let that = this
    this.professionalService.getByCompany(this.company.idEmpresa, function(professionals, error) {
      if(professionals != null) {
        that.professionals = professionals
      } else if(error != null) {
        that.messageService.show(error.error)
      } else {
        that.messageService.show("Ocurrio un error al obtener a los profesionales")
      }
    })
  }

  private getTimePeriods() {
    let that = this
    this.timePeriodService.get(function(timePeriods, error) {
      if(timePeriods != null) {
        that.timePeriods = timePeriods
      } else if(error != null) {
        that.messageService.show(error.error)
      } else {
        that.messageService.show("Ocurrio un error al obtener los periodos de tiempo")
      }
    })
  }

  private getMeses() {
    let that = this
    this.mesService.get(function(meses, error) {
      if(meses != null) {
        that.meses = meses
      } else if(error != null) {
        that.messageService.show(error.error)
      } else {
        that.messageService.show("Ocurrio un error al obtener los meses")
      }
    })
  }

  private getSemanas() {
    let that = this
    this.SemanaService.get(function(semanas, error) {
      if(semanas != null) {
        that.semanas = semanas
      } else if(error != null) {
        that.messageService.show(error.error)
      } else {
        that.messageService.show("Ocurrio un error al obtener las semanas")
      }
    })
  }
}