import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Professional } from '../models/professional'

@Injectable({
  providedIn: 'root'
})
export class ProfessionalService extends ApiService{

	private getByCompanyUrl = 'especialistas/empresas/'
	private headers = {headers: {'Cache-Control' : 'no-cache'}}

	constructor(private http: HttpClient) {
		super()
	}

	getByCompany(companyId, callback) {
		var uri = this.baseUrl + this.getByCompanyUrl + companyId
		this.http.get<[Professional]>(uri, this.headers).subscribe(professionals => {
			callback(professionals, null)
		}, error => {
	    	if (error instanceof ErrorEvent) {
	      		callback(null, null)
		    } else {
		      callback(null, error.error)
		    }
		})
	}
}
