import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  	headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export class ApiService {

	protected baseUrl = 'http://ec2-13-58-160-127.us-east-2.compute.amazonaws.com/rezolv/development/public/';

  	constructor() { }
}
