import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  show(message) {
	$.snackbar({
		content: message,
		timeout: 4000,
	});
  }
}
