import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Company } from '../models/company'

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ApiService {

	private getByEmailUrl = 'empresas/correo/'

  	constructor(private http: HttpClient) {
  		super()
  	}

  	getByEmail(email, callback) {
  		var uri = this.baseUrl + this.getByEmailUrl + email
  		//console.log(uri)
  		this.http.get<Company>(uri).subscribe(auth => {
        	callback(auth, null)
        }, error => {
	        if (error instanceof ErrorEvent) {
	          callback(null, null)
	        } else {
	          callback(null, error.error)
	        }
	    })
  	}
}
