import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Mes } from '../models/mes'

@Injectable({
  providedIn: 'root'
})
export class MesService extends ApiService {

  private getUrl = 'indicadores/consulta/meses'
  private headers = {headers: {'Cache-Control' : 'no-cache'}}

  constructor(private http: HttpClient) {
  	super()
  }

  get(callback) {
    var uri = this.baseUrl + this.getUrl
    this.http.get<[Mes]>(uri, this.headers).subscribe(meses => {
      callback(meses, null)
    }, error => {
        if (error instanceof ErrorEvent) {
            callback(null, null)
        } else {
          callback(null, error.error)
        }
    })
  }
}