import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Auth } from '../models/auth'

@Injectable({
  providedIn: 'root'
})
export class LoginService extends ApiService {

	private verifyTokenUrl = "firebase/verify/{token}"

 	constructor(private http: HttpClient) {
		super()
  	}

  	verifyToken(token, callback){
  		var uri = this.baseUrl + this.verifyTokenUrl.replace("{token}", token)
  		//console.log(uri)
  		this.http.get<Auth>(uri).subscribe(auth => {
        callback(auth, null)
      }, error => {
        if (error instanceof ErrorEvent) {
          callback(null, null)
        } else {
          callback(null, error.error)
        }
      })
  	}
}
