import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ApiService } from './api.service'
import { Indicador } from '../models/indicador'
import { IndicadorDetalle } from '../models/indicador-detalle'
import { Calificacion } from '../models/calificacion'

@Injectable({
  providedIn: 'root'
})
export class IndicadorService extends ApiService {

  private getEmpresaIndicadorSemanalByIdUrl = 'empresas/consulta/indicadores/{idEmpresa}/semanal/{semana}'
  private getEmpresaIndicadorMensualByIdUrl = 'empresas/consulta/indicadores/{idEmpresa}/mensual/{mes}'
  private getEmpresaClientesSemanalByIdUrl = 'empresas/consulta/clientes/{idEmpresa}/semanal/{semana}'
  private getEmpresaClientesMensualByIdUrl = 'empresas/consulta/clientes/{idEmpresa}/mensual/{mes}'
  private getEmpresaIngresosSemanalByIdUrl = 'empresas/consulta/ingresos/{idEmpresa}/semanal/{semana}'
  private getEmpresaIngresosMensualByIdUrl = 'empresas/consulta/ingresos/{idEmpresa}/mensual/{mes}'
  private getEmpresaQuejasSemanalByIdUrl = 'empresas/consulta/quejas/{idEmpresa}/semanal/{semana}'
  private getEmpresaQuejasMensualByIdUrl = 'empresas/consulta/quejas/{idEmpresa}/mensual/{mes}'

  private getEspecialistaIndicadorSemanalByIdUrl= 'especialistas/consulta/indicadores/{idEspecialista}/semanal/{semana}'
  private getEspecialistaIndicadorMensualByIdUrl = 'especialistas/consulta/indicadores/{idEspecialista}/mensual/{mes}'
  private getEspecialistaCalificacionSemanalByIdUrl = 'especialistas/consulta/calificaciones/{idEspecialista}/semanal/{semana}'
  private getEspecialistaCalificacionMensualByIdUrl = 'especialistas/consulta/calificaciones/{idEspecialista}/mensual/{mes}'
  private getEspecialistaClientesSemanalByIdUrl = 'especialistas/consulta/clientes/{idEspecialista}/semanal/{semana}'
  private getEspecialistaClientesMensualByIdUrl = 'especialistas/consulta/clientes/{idEspecialista}/mensual/{mes}'
  private getEspecialistaIngresosSemanalByIdUrl = 'especialistas/consulta/ingresos/{idEspecialista}/semanal/{semana}'
  private getEspecialistaIngresosMensualByIdUrl = 'especialistas/consulta/ingresos/{idEspecialista}/mensual/{mes}'
  private getEspecialistaQuejasSemanalByIdUrl = 'especialistas/consulta/quejas/{idEspecialista}/semanal/{semana}'
  private getEspecialistaQuejasMensualByIdUrl = 'especialistas/consulta/quejas/{idEspecialista}/mensual/{mes}'
  
  private headers = {headers: {'Cache-Control' : 'no-cache'}}

  constructor(private http: HttpClient) {
  	super()
  }

  getEmpresaIndicadorSemanalById(idEmpresa, semana, callback) {
    var uri = this.baseUrl + this.getEmpresaIndicadorSemanalByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{semana}", semana)
    this.http.get<Indicador>(uri, this.headers).subscribe(indicador => {
      callback(indicador, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaIndicadorMensualById(idEmpresa, mes, callback) {
    var uri = this.baseUrl + this.getEmpresaIndicadorMensualByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{mes}", mes)
    this.http.get<Indicador>(uri, this.headers).subscribe(indicador => {
      callback(indicador, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaClientesSemanalById(idEmpresa, semana, callback) {
    var uri = this.baseUrl + this.getEmpresaClientesSemanalByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaClientesMensualById(idEmpresa, mes, callback) {
    var uri = this.baseUrl + this.getEmpresaClientesMensualByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaIngresosSemanalById(idEmpresa, semana, callback) {
    var uri = this.baseUrl + this.getEmpresaIngresosSemanalByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaIngresosMensualById(idEmpresa, mes, callback) {
    var uri = this.baseUrl + this.getEmpresaIngresosMensualByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaQuejasSemanalById(idEmpresa, semana, callback) {
    var uri = this.baseUrl + this.getEmpresaQuejasSemanalByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEmpresaQuejasMensualById(idEmpresa, mes, callback) {
    var uri = this.baseUrl + this.getEmpresaQuejasMensualByIdUrl.replace("{idEmpresa}", idEmpresa).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaIndicadorSemanalById(idEspecialista, semana, callback) {
    var uri = this.baseUrl + this.getEspecialistaIndicadorSemanalByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{semana}", semana)
    this.http.get<Indicador>(uri, this.headers).subscribe(indicador => {
      callback(indicador, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaIndicadorMensualById(idEspecialista, mes, callback) {
    var uri = this.baseUrl + this.getEspecialistaIndicadorMensualByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{mes}", mes)
    this.http.get<Indicador>(uri, this.headers).subscribe(indicador => {
      callback(indicador, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaCalificacionSemanalById(idEspecialista, semana, callback) {
    var uri = this.baseUrl + this.getEspecialistaCalificacionSemanalByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{semana}", semana)
    this.http.get<[Calificacion]>(uri, this.headers).subscribe(calificacion => {
      callback(calificacion, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaCalificacionMensualById(idEspecialista, mes, callback) {
    var uri = this.baseUrl + this.getEspecialistaCalificacionMensualByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{mes}", mes)
    this.http.get<[Calificacion]>(uri, this.headers).subscribe(calificacion => {
      callback(calificacion, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaClientesSemanalById(idEspecialista, semana, callback) {
    var uri = this.baseUrl + this.getEspecialistaClientesSemanalByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaClientesMensualById(idEspecialista, mes, callback) {
    var uri = this.baseUrl + this.getEspecialistaClientesMensualByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaIngresosSemanalById(idEspecialista, semana, callback) {
    var uri = this.baseUrl + this.getEspecialistaIngresosSemanalByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaIngresosMensualById(idEspecialista, mes, callback) {
    var uri = this.baseUrl + this.getEspecialistaIngresosMensualByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaQuejasSemanalById(idEspecialista, semana, callback) {
    var uri = this.baseUrl + this.getEspecialistaQuejasSemanalByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{semana}", semana)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }

  getEspecialistaQuejasMensualById(idEspecialista, mes, callback) {
    var uri = this.baseUrl + this.getEspecialistaQuejasMensualByIdUrl.replace("{idEspecialista}", idEspecialista).replace("{mes}", mes)
    this.http.get<[IndicadorDetalle]>(uri, this.headers).subscribe(indicadorDetalle => {
      callback(indicadorDetalle, null)
    }, error => {
      if (error instanceof ErrorEvent) {
        callback(null, null)
      } else {
        callback(null, error.error)
      }
    })
  }
}
