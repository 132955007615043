import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndicadoresService {

  private idEmpresa = new BehaviorSubject(-1);
  currentIdEmpresa = this.idEmpresa.asObservable();
  private idEmpleado = new BehaviorSubject(-1);
  currentIdEmpleado = this.idEmpleado.asObservable();
  private mes = new BehaviorSubject(-1);
  currentMes = this.mes.asObservable();
  private semana = new BehaviorSubject(-1);
  currentSemana = this.semana.asObservable();

  private periodo = new BehaviorSubject(-1);
  currentPeriodo = this.periodo.asObservable();

  constructor() { }
  
  setEmpresa(idEmpresa: number) {
    this.idEmpresa.next(idEmpresa)
  }

  setProfesional(idEmpleado: number) {
    this.idEmpleado.next(idEmpleado)
  }

  setMes(mes: number) {
    this.mes.next(mes)
  }

  setSemana(semana: number) {
    this.semana.next(semana)
  }

  setPeriodo(periodo: number) {
    this.periodo.next(periodo)
  }

}
