import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

	public token = "token"
  public company = "company"

  constructor() { }

  exists(key: string): boolean {
  	return localStorage.getItem(key) != null
  }

  save(key: string, value: any): void {
  	localStorage.setItem(key, value)
  }

  delete(key: string): void {
  	localStorage.removeItem(key)
  }

  get(key: string): any {
  	return localStorage.getItem(key)
  }

  clear() {
    localStorage.clear()
  }
}
